import {google, outlook, office365, yahoo, ics} from 'calendar-link';

if (document.querySelector('.calendar-wrapper')) {
    const wordpressUrl = 'https://akademiafb.pl';
    const customPostType = 'event_type';
    let allEventsCache = null;
    let isDataFetching = false;
    const buttons = document.querySelectorAll('.filter');
    var category;
    var inprogress = false;
    var loadedOnce = false;

    buttons.forEach((button) => {
        button.addEventListener('click', (event) => {
            if (isDataFetching) {
                return;
            }
            const wasSelected = event.target.classList.contains('selected');
            buttons.forEach((btn) => btn.classList.remove('selected'));
            if (!wasSelected) {
                event.target.classList.add('selected');
                category = event.target.dataset.category;
            } else {
                category = false;
            }
            displayEventTypes(category);
        });
    });

    function refreshTile() {
        let addToCalendar = document.querySelectorAll('.addToCalendar');
        //console.log('Funkcja: ' + addToCalendar);
        addToCalendar.forEach((button) => {
            button.addEventListener('click', function () {
                //console.log('Click!');
                let buttonContainer = button.closest('.default-buttons');
                let calendarButtons = buttonContainer.nextElementSibling;
                let state = button.getAttribute('data-state') === 'false';
                if (state === false) {
                    console.log('Click! true');
                    buttonContainer.style.display = 'flex';
                    calendarButtons.style.display = 'none';
                    button.setAttribute('data-state', 'false');
                } else {
                    console.log('Click! false');
                    buttonContainer.style.display = 'none';
                    calendarButtons.style.display = 'flex';
                    button.setAttribute('data-state', 'true');
                }
            });
        });
    }

    // Left calendar
    const prevMonthBtn = document.getElementById('prev-month');
    const nextMonthBtn = document.getElementById('next-month');
    const currentMonthLabel = document.getElementById('current-month');
    const daysContainer = document.querySelector('.calendar .days');
    let currentDate = new Date();
    let currentWeekStart = new Date();
    prevMonthBtn.addEventListener('click', showPreviousMonth);
    nextMonthBtn.addEventListener('click', showNextMonth);
    displayCalendar();

    if (window.innerWidth <= 768) {
        document.querySelectorAll('.monthly.selected, .mobile-events .event').forEach((event) => {
            event.classList.remove('selected');
        });

        let today = new Date();
        const formattedDate = today.toISOString().slice(0, 10).replace(/-/g, '');
        document.querySelector(`.monthly-${formattedDate}`).classList.add('selected');
    }

    async function displayCalendar() {
        daysContainer.innerHTML = '';
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        currentMonthLabel.textContent = getMonthName(month);
        const firstDayOfMonth = new Date(year, month, 1);
        const numberOfDays = new Date(year, month + 1, 0).getDate();
        let firstDayIndex = firstDayOfMonth.getDay();
        if (firstDayIndex === 0) {
            firstDayIndex = 6;
        } else {
            firstDayIndex--;
        }
        const prevMonthDays = new Date(year, month, 0).getDate();
        for (let i = 0; i < firstDayIndex; i++) {
            const cell = document.createElement('div');
            const prevMonthDay = prevMonthDays - (firstDayIndex - 1 - i);
            cell.textContent = prevMonthDay;
            cell.classList.add('monthly', 'other-month'); // Dodajemy klasę, aby zaznaczyć, że to dni z innego miesiąca
            daysContainer.appendChild(cell);
        }

        let weekStartDate = getWeekStartDate(currentWeekStart);
        weekStartDate = new Date(weekStartDate.getTime() - 24 * 60 * 60 * 1000); // -1 dzień
        const weekEndDate = new Date(weekStartDate.getTime() + 6 * 24 * 60 * 60 * 1000);

        for (let day = 1; day <= numberOfDays; day++) {
            const cell = document.createElement('div');
            const cellDate = new Date(year, month, day);
            const cellEvents = document.createElement('div');
            cellEvents.classList.add('events');
            cell.classList.add('monthly');
            cell.dataset.date = year + String(month + 1).padStart(2, '0') + String(day).padStart(2, '0');
            cell.classList.add('monthly-' + year + String(month + 1).padStart(2, '0') + String(day).padStart(2, '0'));
            cell.textContent = day;
            daysContainer.appendChild(cell);
            cell.appendChild(cellEvents);

            if (window.innerWidth > 768) {
                if (cellDate.getTime() >= weekStartDate.getTime() && cellDate.getTime() <= weekEndDate.getTime()) {
                    cell.classList.add('highlight');
                }
            }

            if (cellDate.getDay() === 0) cell.classList.add('sunday');
        }

        let lastDayOfMonth = new Date(year, month + 1, 0).getDay();

        if (lastDayOfMonth === 0) {
            lastDayOfMonth = 6;
        } else {
            lastDayOfMonth--;
        }

        for (let i = lastDayOfMonth; i < 6; i++) {
            const cell = document.createElement('div');
            const nextMonthDay = i - lastDayOfMonth + 1;
            cell.textContent = nextMonthDay;
            cell.classList.add('monthly', 'other-month');
            daysContainer.appendChild(cell);
        }

        document.querySelectorAll('.monthly').forEach((el) => {
            if (window.innerWidth <= 768) {
                el.addEventListener('click', (event) => {
                    let clickedDate = event.target.dataset.date;

                    // First, remove 'selected' class from all calendar dates and mobile events
                    document.querySelectorAll('.monthly.selected, .mobile-events .event').forEach((event) => {
                        event.classList.remove('selected');
                    });

                    // Add 'selected' class to the clicked calendar date
                    event.target.classList.add('selected');

                    // Then, check for matching events and act accordingly
                    let matchingEvents = document.querySelectorAll(`.mobile-events .event.date-${clickedDate}`);
                    if (matchingEvents.length > 0) {
                        matchingEvents.forEach((matchingEvent) => {
                            matchingEvent.classList.add('selected');
                        });
                        document.querySelector('.mobile-info').style.display = 'none';
                    } else {
                        document.querySelector('.mobile-info').style.display = 'block';
                    }
                });
            } else {
                el.addEventListener('click', (event) => {
                    //return;
                    let clickedDate = event.target.dataset.date;
                    const dateObject = new Date(parseInt(clickedDate.substring(0, 4)), parseInt(clickedDate.substring(4, 6)) - 1, parseInt(clickedDate.substring(6, 8)));

                    currentWeekStart = new Date(dateObject);
                    const dayOfWeek = currentWeekStart.getDay();
                    const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
                    currentWeekStart.setDate(dateObject.getDate() - daysToSubtract);

                    currentDate.setMonth(dateObject.getMonth());
                    currentWeekStart.setMonth(currentWeekStart.getMonth());
                    displayCalendar();
                    displayTimetable();

                    // hack

                    let tmpWeekStart = new Date();
                    tmpWeekStart.setDate(currentWeekStart.getDate() - 1);
                    const formattedDate = currentWeekStart.toISOString().slice(0, 10).replace(/-/g, '');
                    document.querySelector(`.monthly-${formattedDate}`).classList.remove('highlight');

                    if (window.innerWidth > 768) {
                        let highlight = document.querySelectorAll(`.schedule .date-${clickedDate}`);

                        highlight.forEach((element) => {
                            if (element !== null) element.classList.add('highlight');
                        });

                        setTimeout(() => {
                            highlight.forEach((element) => {
                                element.classList.remove('highlight');
                            });
                        }, 1000);
                    }
                });

                el.addEventListener('mouseover', (event) => {
                    let clickedDate = event.target.dataset.date;

                    document.querySelectorAll('.monthly.selected, .mobile-events .event').forEach((event) => {
                        event.classList.remove('selected');
                    });

                    let matchingEvents = document.querySelectorAll(`.mobile-events .event.date-${clickedDate}`);
                    if (matchingEvents) {
                        matchingEvents.forEach((matchingEvent) => {
                            matchingEvent.classList.add('selected');
                        });
                    }

                    // Add 'selected' class to the clicked calendar date
                    event.target.classList.add('selected');
                    //leftBox.classList.add('opened');
                });

                el.addEventListener('mouseleave', (event) => {
                    let clickedDate = event.target.dataset.date;

                    let matchingEvents = document.querySelectorAll(`.mobile-events .event.date-${clickedDate}`);
                    if (matchingEvents) {
                        matchingEvents.forEach((matchingEvent) => {
                            matchingEvent.classList.remove('selected');
                        });
                    }
                    event.target.classList.remove('selected');
                });
            }
        });

        await displayEventTypes();
        let today = new Date();
        let todayFormatted = `${today.getFullYear()}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getDate().toString().padStart(2, '0')}`;

        // Find today's cell in the calendar
        let todayCell = document.querySelector(`.monthly-${todayFormatted}`);
        let matchingEvent = document.querySelector(`.mobile-events .event.date-${todayFormatted}`);

        if (todayCell && matchingEvent) {
            if (loadedOnce !== true) {
                todayCell.classList.add('selected');
                loadedOnce = true;
            }

            matchingEvent.classList.add('selected');
        }
    }

    function showPreviousMonth() {
        currentDate.setMonth(currentDate.getMonth() - 1);
        currentWeekStart.setMonth(currentWeekStart.getMonth() - 1);
        displayCalendar();
        displayTimetable();
    }

    function showNextMonth() {
        currentDate.setMonth(currentDate.getMonth() + 1);
        currentWeekStart.setMonth(currentWeekStart.getMonth() + 1);
        displayCalendar();
        displayTimetable();
    }

    function getMonthName(month) {
        const months = ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'];
        return months[month];
    }

    // Right calendar
    const prevWeekBtn = document.getElementById('prev-week');
    const nextWeekBtn = document.getElementById('next-week');
    const currentWeekLabel = document.getElementById('current-week');
    const scheduleContainer = document.querySelector('.calendar.right .schedule');

    prevWeekBtn.addEventListener('click', showPreviousWeek);
    nextWeekBtn.addEventListener('click', showNextWeek);

    displayTimetable();

    function displayTimetable() {
        scheduleContainer.innerHTML = '';
        let startDate = getWeekStartDate(currentWeekStart);
        if (startDate.getDay() === 0) {
            // jeśli to niedziela, przesuwamy o jeden dzień do przodu
            startDate = new Date(startDate.setDate(startDate.getDate() + 1));
        }
        const endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000);

        let firstMonth = getMonthName(startDate.getMonth());
        let lastMonth = getMonthName(endDate.getMonth());

        if (firstMonth == lastMonth) currentWeekLabel.textContent = firstMonth + ' ' + startDate.getFullYear();
        else currentWeekLabel.textContent = firstMonth + ' ' + startDate.getFullYear();

        let i = 0;
        document.querySelectorAll('.day-of-month').forEach((el) => {
            let day = new Date(startDate);
            day.setDate(startDate.getDate() + i);
            el.textContent = day.getDate();
            i++;
        });

        for (let hour = 7; hour <= 22; hour++) {
            for (let day = 0; day < 7; day++) {
                const date = new Date(startDate);
                date.setDate(date.getDate() + day);

                if (date.getDay() === 0) {
                    continue;
                }

                const cell = document.createElement('div');
                if (hour.toString().length < 2) hour = '0' + hour;
                cell.classList.add('hour-' + hour, 'date-' + formatDate(date));
                scheduleContainer.appendChild(cell);
            }
        }
    }

    function showPreviousWeek() {
        currentWeekStart.setDate(currentWeekStart.getDate() - 7);
        currentDate.setDate(currentDate.getDate() - 7);
        displayTimetable();
        displayCalendar();
    }

    function showNextWeek() {
        currentWeekStart.setDate(currentWeekStart.getDate() + 7);
        currentDate.setDate(currentDate.getDate() + 7);
        displayTimetable();
        displayCalendar();
    }

    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return year + month + day;
    }

    function getWeekStartDate(date) {
        const dayIndex = date.getDay();
        const diff = dayIndex === 0 ? 6 : dayIndex - 1;
        const start = new Date(date);
        start.setDate(start.getDate() - diff);
        return start;
    }
    async function getEventTypesFromWordPressAPI(category = false) {
        if (allEventsCache) {
            //console.log('Pobieranie danych z cache...');
            const cachedData = category ? allEventsCache.filter((event) => event.category === Number(category)) : allEventsCache;
            //console.log('Dane z cache:', cachedData);
            hideLoader();
            return cachedData;
        }

        try {
            const response = await fetch(`${wordpressUrl}/wp-json/wp/v2/${customPostType}?orderby=date&order=asc&per_page=100`);

            if (!response.ok) {
                throw new Error(`Error: Unable to fetch data. Status code: ${response.status}`);
            }
            const rawData = await response.json();

            const processedData = await Promise.all(
                rawData.map(async (post) => {
                    const instructorData = await fetchInstructorData(post.acf.prowadzacy);
                    return {
                        title: post.title.rendered,
                        category: post.categories[0],
                        data: post.acf.data,
                        poczatek: post.acf.poczatek,
                        koniec: post.acf.koniec,
                        lokalizacja: post.acf.lokalizacja,
                        szkolenie: post.acf.szkolenie,
                        zapisy_zew: post.acf.zapisy_zew,
                        prowadzacy: {
                            id: post.acf.prowadzacy,
                            name: instructorData?.name || null,
                            link: instructorData?.link || null,
                        },
                    };
                })
            );

            //console.log(`Pobrano ${processedData.length} postów z API.`);
            hideLoader();
            allEventsCache = processedData;

            return category ? processedData.filter((event) => event.category === category) : processedData;
        } catch (error) {
            console.error(error);
            isDataFetching = false;
            hideLoader();
            return null;
        }
    }

    function hideLoader() {
        let loader = document.getElementById('loader');
        loader.style.opacity = '0';
        setTimeout(() => {
            loader.style.display = 'none';
        }, 300);
    }

    async function fetchInstructorData(instructorId) {
        try {
            const response = await fetch(`${wordpressUrl}/wp-json/wp/v2/kadra/${instructorId}`);

            if (!response.ok) {
                throw new Error(`Error fetching instructor data. Status code: ${response.status}`);
            }

            const instructorPost = await response.json();
            return {
                name: instructorPost.title.rendered,
                link: instructorPost.link,
            };
        } catch (error) {
            console.error(`Error fetching instructor data for ID: ${instructorId}.`, error);
            return {
                name: null,
                link: null,
            };
        }
    }

    async function displayEventTypes(category = false) {
        isDataFetching = true;
        if (inprogress === true) return;

        inprogress = true;

        const events = document.querySelectorAll('.event');

        events.forEach((event) => {
            event.remove();
        });

        let eventTypes;
        try {
            eventTypes = await getEventTypesFromWordPressAPI(category);
        } catch (error) {
            console.error('There was an error fetching event types:', error);
            return;
        }
        if (eventTypes && eventTypes.length > 0) {
            for (let eventType of eventTypes) {
                const eventTypeDiv = document.createElement('div');
                const eventDotDiv = document.createElement('div');
                const eventMobileDiv = document.createElement('div');

                let eventProwadzacyName = eventType.prowadzacy.name;

                function timeStringToDate(timeString) {
                    const [hours, minutes, seconds] = timeString.split(':').map(Number);
                    const date = new Date();
                    date.setHours(hours, minutes, seconds, 0);
                    return date;
                }
                const eventDate = eventType.data;
                const poczatekDate = timeStringToDate(eventType.poczatek.toString());
                const koniecDate = timeStringToDate(eventType.koniec.toString());
                const eventCategory = eventType.category;
                const eventSzkolenie = eventType.szkolenie;
                const zapisy_zew = eventType.zapisy_zew;
                if (zapisy_zew) {
                    var zapisy = zapisy_zew;
                } else {
                    var zapisy = `/formularz/?szkolenie_id=${eventSzkolenie}`;
                }
                const differenceInMilliseconds = koniecDate - poczatekDate;
                const differenceInHours = differenceInMilliseconds / (3600 * 1000);

                // Skoro mamy już sformatowaną datę, możemy ją użyć bezpośrednio:
                const fullDate = `${eventType.data} ${eventType.poczatek} +0200`;
                const formattedDate = fullDate.replace(/^(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');

                const event = {
                    title: eventType.title,
                    start: formattedDate,
                    duration: [differenceInHours, 'hour'],
                    // location: eventType.lokalizacja,
                };

                eventTypeDiv.classList.add('event');
                eventTypeDiv.classList.add('category-' + eventCategory);
                eventDotDiv.classList.add('dots');
                eventDotDiv.classList.add('event');

                eventDotDiv.classList.add('category-' + eventCategory);
                eventMobileDiv.classList.add('event');
                eventMobileDiv.classList.add('category-' + eventCategory);
                eventMobileDiv.classList.add('date-' + eventDate);
                eventMobileDiv.dataset.date = eventDate;

                let eventHour = eventType.poczatek.toString().substring(0, 2);
                let eventContainer = document.querySelector(`.hour-${eventHour}.date-${eventDate}`);
                let eventDotContainer = document.querySelector(`.monthly-${eventDate} .events`);
                let eventMobileContainer = document.querySelector(`.mobile-events`);

                function calendarLinksClose(buttonContainer) {
                    let addToCalendar = buttonContainer.querySelector('.addToCalendar');
                    let calendarButtons = buttonContainer.nextElementSibling;

                    buttonContainer.style.display = 'flex';
                    calendarButtons.style.display = 'none';
                    addToCalendar.setAttribute('data-state', 'false');
                }

                function handleClick(e) {
                    var debounceTimer;
                    var buttonMenu = e.target.closest('.button-menu');
                    var iconClose = e.target.closest('.icon-close'); // Wyszukaj element .icon-close

                    if (buttonMenu) {
                        if (debounceTimer) {
                            return;
                        }

                        // Zamknij wszystkie elementy .button-menu
                        var allButtonMenus = document.querySelectorAll('.button-menu.open');
                        allButtonMenus.forEach(function (menu) {
                            var parentEventForOtherMenus = menu.closest('.event');
                            if (parentEventForOtherMenus) {
                                var buttonContainerForOtherMenus = parentEventForOtherMenus.querySelector('.default-buttons');

                                parentEventForOtherMenus.classList.remove('open');
                                menu.classList.remove('open');
                                calendarLinksClose(buttonContainerForOtherMenus);
                            }
                        });

                        // Otwórz kliknięty .button-menu
                        var parentEvent = buttonMenu.closest('.event');
                        if (parentEvent) {
                            var buttonContainer = parentEvent.querySelector('.default-buttons');

                            parentEvent.classList.toggle('open');
                            buttonMenu.classList.toggle('open');
                            calendarLinksClose(buttonContainer);
                        }

                        debounceTimer = setTimeout(function () {
                            debounceTimer = null;
                        }, 180);
                    }

                    // Jeśli kliknięto w .icon-close
                    if (iconClose) {
                        var parentEventForClose = iconClose.closest('.event');
                        if (parentEventForClose) {
                            var buttonMenuForClose = parentEventForClose.querySelector('.button-menu');
                            var buttonContainerForClose = parentEventForClose.querySelector('.default-buttons');

                            parentEventForClose.classList.remove('open');
                            if (buttonMenuForClose) {
                                buttonMenuForClose.classList.remove('open');
                            }
                            calendarLinksClose(buttonContainerForClose);
                        }
                    }
                }

                if (eventTypeDiv) {
                    eventTypeDiv.addEventListener('click', handleClick);
                }
                if (eventMobileDiv) {
                    eventMobileDiv.addEventListener('click', handleClick);
                }

                eventTypeDiv.innerHTML = `
                    <div class="category"></div>
                    <button class="button-menu" title="menu"><svg class="icon-open" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line y1="1" x2="17" y2="1" stroke="black" stroke-width="2"/>
                <line y1="8" x2="17" y2="8" stroke="black" stroke-width="2"/>
                <line y1="15" x2="17" y2="15" stroke="black" stroke-width="2"/>
                </svg><svg class="icon-close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="close"><path d="M13.41,12l6.3-6.29a1,1,0,1,0-1.42-1.42L12,10.59,5.71,4.29A1,1,0,0,0,4.29,5.71L10.59,12l-6.3,6.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l6.29,6.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg></button>
                    <h3 class="event-title">${eventType.title}</h3>
                    <p class="event-time">${eventType.poczatek.toString().substring(0, 5)}-${eventType.koniec.toString().substring(0, 5)}</p>
                    <p class="event-leader">Prowadzący: <br /> ${eventProwadzacyName}</p>
                    <p class="event-place"><svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 3.21432C5.4606 3.21432 4.93331 3.37142 4.48481 3.66574C4.03631 3.96007 3.68675 4.37841 3.48033 4.86786C3.27391 5.35731 3.2199 5.89589 3.32513 6.41548C3.43036 6.93508 3.69011 7.41236 4.07153 7.78697C4.45294 8.16158 4.9389 8.41669 5.46794 8.52005C5.99697 8.6234 6.54534 8.57035 7.04368 8.36762C7.54203 8.16488 7.96797 7.82156 8.26764 7.38107C8.56732 6.94057 8.72727 6.42269 8.72727 5.89292C8.72727 5.18251 8.43994 4.5012 7.92847 3.99886C7.41701 3.49653 6.72332 3.21432 6 3.21432ZM6 7.50007C5.67636 7.50007 5.35998 7.40582 5.09089 7.22922C4.82179 7.05262 4.61205 6.80162 4.4882 6.50795C4.36434 6.21428 4.33194 5.89113 4.39508 5.57937C4.45822 5.26762 4.61407 4.98125 4.84292 4.75648C5.07177 4.53172 5.36334 4.37865 5.68076 4.31664C5.99818 4.25463 6.3272 4.28645 6.62621 4.40809C6.92522 4.52974 7.18078 4.73573 7.36059 5.00003C7.54039 5.26432 7.63636 5.57505 7.63636 5.89292C7.63636 6.31916 7.46396 6.72795 7.15708 7.02935C6.85021 7.33075 6.43399 7.50007 6 7.50007ZM6 0C4.40926 0.00177238 2.88418 0.623201 1.75935 1.72795C0.634525 2.8327 0.00180459 4.33056 0 5.89292C0 7.99562 0.989318 10.2242 2.86364 12.3383C3.70583 13.2936 4.65372 14.1538 5.68977 14.9031C5.78149 14.9662 5.89075 15 6.00273 15C6.1147 15 6.22397 14.9662 6.31568 14.9031C7.34983 14.1535 8.29587 13.2933 9.13636 12.3383C11.008 10.2242 12 7.99562 12 5.89292C11.9982 4.33056 11.3655 2.8327 10.2406 1.72795C9.11582 0.623201 7.59075 0.00177238 6 0ZM6 13.7948C4.87295 12.9242 1.09091 9.72666 1.09091 5.89292C1.09091 4.61418 1.60812 3.38782 2.52875 2.48362C3.44938 1.57941 4.69803 1.07144 6 1.07144C7.30197 1.07144 8.55062 1.57941 9.47125 2.48362C10.3919 3.38782 10.9091 4.61418 10.9091 5.89292C10.9091 9.72532 7.12705 12.9242 6 13.7948Z" fill="black"/>
                        </svg>${eventType.lokalizacja}</p>
                        <div class="informations">
                            <div class="default-buttons">
                            <a href="/?p=${eventSzkolenie}" target="_blank"><svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="34" height="34" rx="17" fill="#E34212"/>
                            <path d="M20 25.5C20 25.8978 19.842 26.2794 19.5607 26.5607C19.2794 26.842 18.8978 27 18.5 27C17.7044 27 16.9413 26.6839 16.3787 26.1213C15.8161 25.5587 15.5 24.7956 15.5 24V16.5C15.1022 16.5 14.7206 16.342 14.4393 16.0607C14.158 15.7794 14 15.3978 14 15C14 14.6022 14.158 14.2206 14.4393 13.9393C14.7206 13.658 15.1022 13.5 15.5 13.5C16.2956 13.5 17.0587 13.8161 17.6213 14.3787C18.1839 14.9413 18.5 15.7044 18.5 16.5V24C18.8978 24 19.2794 24.158 19.5607 24.4393C19.842 24.7206 20 25.1022 20 25.5ZM14 8.25C14 7.80499 14.132 7.36998 14.3792 6.99997C14.6264 6.62996 14.9778 6.34157 15.389 6.17127C15.8001 6.00097 16.2525 5.95642 16.689 6.04323C17.1254 6.13005 17.5263 6.34434 17.841 6.65901C18.1557 6.97368 18.37 7.37459 18.4568 7.81105C18.5436 8.2475 18.499 8.6999 18.3287 9.11104C18.1584 9.52217 17.87 9.87357 17.5 10.1208C17.13 10.368 16.695 10.5 16.25 10.5C15.6533 10.5 15.081 10.2629 14.659 9.84099C14.2371 9.41903 14 8.84674 14 8.25Z" fill="white"/>
                            </svg>Info</a>
                            
                            <a href="${eventType.prowadzacy.link}" target="_blank"><svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="34" height="34" rx="17" fill="#E34212"/>
                            <path d="M26.6489 24.8756C25.2211 22.4072 23.0208 20.6372 20.4529 19.7981C21.7231 19.042 22.7099 17.8898 23.2619 16.5185C23.8139 15.1473 23.9004 13.6327 23.5083 12.2075C23.1162 10.7823 22.2671 9.52515 21.0914 8.62921C19.9156 7.73327 18.4783 7.24805 17.0001 7.24805C15.5219 7.24805 14.0846 7.73327 12.9089 8.62921C11.7332 9.52515 10.8841 10.7823 10.492 12.2075C10.0998 13.6327 10.1864 15.1473 10.7384 16.5185C11.2903 17.8898 12.2772 19.042 13.5473 19.7981C10.9795 20.6362 8.77919 22.4062 7.35138 24.8756C7.29902 24.961 7.26429 25.056 7.24924 25.155C7.23419 25.254 7.23912 25.355 7.26375 25.4521C7.28837 25.5492 7.33219 25.6404 7.39262 25.7202C7.45305 25.8001 7.52887 25.867 7.61559 25.9171C7.70232 25.9672 7.7982 25.9995 7.89758 26.0119C7.99695 26.0243 8.09782 26.0167 8.19421 25.9896C8.29061 25.9624 8.38059 25.9162 8.45884 25.8537C8.53709 25.7912 8.60203 25.7136 8.64982 25.6256C10.4161 22.5731 13.5379 20.7506 17.0001 20.7506C20.4623 20.7506 23.5842 22.5731 25.3504 25.6256C25.3982 25.7136 25.4632 25.7912 25.5414 25.8537C25.6197 25.9162 25.7097 25.9624 25.806 25.9896C25.9024 26.0167 26.0033 26.0243 26.1027 26.0119C26.2021 25.9995 26.2979 25.9672 26.3847 25.9171C26.4714 25.867 26.5472 25.8001 26.6076 25.7202C26.6681 25.6404 26.7119 25.5492 26.7365 25.4521C26.7611 25.355 26.7661 25.254 26.751 25.155C26.736 25.056 26.7012 24.961 26.6489 24.8756ZM11.7501 14.0006C11.7501 12.9622 12.058 11.9472 12.6349 11.0839C13.2118 10.2205 14.0317 9.54759 14.991 9.15023C15.9504 8.75287 17.006 8.6489 18.0244 8.85147C19.0428 9.05405 19.9782 9.55406 20.7124 10.2883C21.4467 11.0225 21.9467 11.958 22.1493 12.9764C22.3518 13.9948 22.2479 15.0504 21.8505 16.0097C21.4531 16.969 20.7802 17.7889 19.9169 18.3658C19.0535 18.9427 18.0385 19.2506 17.0001 19.2506C15.6082 19.2491 14.2737 18.6955 13.2895 17.7113C12.3052 16.727 11.7516 15.3925 11.7501 14.0006Z" fill="white"/>
                            </svg>Prowadzący</a>
                            
                            <button class="addToCalendar" data-state="false"><svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="34" height="34" rx="17" fill="#E34212"/>
                            <path d="M24.5 8H22.25V7.25C22.25 7.05109 22.171 6.86032 22.0303 6.71967C21.8897 6.57902 21.6989 6.5 21.5 6.5C21.3011 6.5 21.1103 6.57902 20.9697 6.71967C20.829 6.86032 20.75 7.05109 20.75 7.25V8H13.25V7.25C13.25 7.05109 13.171 6.86032 13.0303 6.71967C12.8897 6.57902 12.6989 6.5 12.5 6.5C12.3011 6.5 12.1103 6.57902 11.9697 6.71967C11.829 6.86032 11.75 7.05109 11.75 7.25V8H9.5C9.10218 8 8.72064 8.15804 8.43934 8.43934C8.15804 8.72064 8 9.10218 8 9.5V24.5C8 24.8978 8.15804 25.2794 8.43934 25.5607C8.72064 25.842 9.10218 26 9.5 26H24.5C24.8978 26 25.2794 25.842 25.5607 25.5607C25.842 25.2794 26 24.8978 26 24.5V9.5C26 9.10218 25.842 8.72064 25.5607 8.43934C25.2794 8.15804 24.8978 8 24.5 8ZM11.75 9.5V10.25C11.75 10.4489 11.829 10.6397 11.9697 10.7803C12.1103 10.921 12.3011 11 12.5 11C12.6989 11 12.8897 10.921 13.0303 10.7803C13.171 10.6397 13.25 10.4489 13.25 10.25V9.5H20.75V10.25C20.75 10.4489 20.829 10.6397 20.9697 10.7803C21.1103 10.921 21.3011 11 21.5 11C21.6989 11 21.8897 10.921 22.0303 10.7803C22.171 10.6397 22.25 10.4489 22.25 10.25V9.5H24.5V12.5H9.5V9.5H11.75ZM24.5 24.5H9.5V14H24.5V24.5ZM20.9056 16.4694C20.9754 16.539 21.0307 16.6217 21.0684 16.7128C21.1062 16.8038 21.1256 16.9014 21.1256 17C21.1256 17.0986 21.1062 17.1962 21.0684 17.2872C21.0307 17.3783 20.9754 17.461 20.9056 17.5306L16.4056 22.0306C16.336 22.1004 16.2533 22.1557 16.1622 22.1934C16.0712 22.2312 15.9736 22.2506 15.875 22.2506C15.7764 22.2506 15.6788 22.2312 15.5878 22.1934C15.4967 22.1557 15.414 22.1004 15.3444 22.0306L13.0944 19.7806C12.9536 19.6399 12.8746 19.449 12.8746 19.25C12.8746 19.051 12.9536 18.8601 13.0944 18.7194C13.2351 18.5786 13.426 18.4996 13.625 18.4996C13.824 18.4996 14.0149 18.5786 14.1556 18.7194L15.875 20.4397L19.8444 16.4694C19.914 16.3996 19.9967 16.3443 20.0878 16.3066C20.1788 16.2688 20.2764 16.2494 20.375 16.2494C20.4736 16.2494 20.5712 16.2688 20.6622 16.3066C20.7533 16.3443 20.836 16.3996 20.9056 16.4694Z" fill="white"/>
                            </svg> Dodaj do kalendarza</button>
                            <a href="${zapisy}" target="_blank"><svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="34" height="34" rx="17" fill="#E34212"/>
                            <path d="M25.5607 18.0607C25.842 17.7794 26 17.3978 26 17C26 16.6022 25.842 16.2206 25.5607 15.9393C25.2794 15.658 24.8978 15.5 24.5 15.5H18.5V9.5C18.5 9.10218 18.342 8.72064 18.0607 8.43934C17.7794 8.15804 17.3978 8 17 8C16.6022 8 16.2206 8.15804 15.9393 8.43934C15.658 8.72064 15.5 9.10218 15.5 9.5V15.5H9.5C9.10218 15.5 8.72064 15.658 8.43934 15.9393C8.15804 16.2206 8 16.6022 8 17C8 17.3978 8.15804 17.7794 8.43934 18.0607C8.72064 18.342 9.10218 18.5 9.5 18.5H15.5V24.5C15.5 24.8978 15.658 25.2794 15.9393 25.5607C16.2206 25.842 16.6022 26 17 26C17.3978 26 17.7794 25.842 18.0607 25.5607C18.342 25.2794 18.5 24.8978 18.5 24.5V18.5H24.5C24.8978 18.5 25.2794 18.342 25.5607 18.0607Z" fill="white"/>
                            </svg>Zapisz się</a>
                            </div>

                            <div class="add-to-calendar">
                            <a class="mail" target="_blank" href="${google(event)}"><svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M27.7569 12.5519H26.75V12.5H15.5V17.5H22.5644C21.5338 20.4106 18.7644 22.5 15.5 22.5C11.3581 22.5 8 19.1419 8 15C8 10.8581 11.3581 7.5 15.5 7.5C17.4119 7.5 19.1513 8.22125 20.4756 9.39937L24.0113 5.86375C21.7788 3.78312 18.7925 2.5 15.5 2.5C8.59687 2.5 3 8.09687 3 15C3 21.9031 8.59687 27.5 15.5 27.5C22.4031 27.5 28 21.9031 28 15C28 14.1619 27.9138 13.3438 27.7569 12.5519Z" fill="#FFC107"/>
                            <path d="M4.44141 9.18187L8.54828 12.1938C9.65953 9.4425 12.3508 7.5 15.5002 7.5C17.412 7.5 19.1514 8.22125 20.4758 9.39937L24.0114 5.86375C21.7789 3.78312 18.7927 2.5 15.5002 2.5C10.6989 2.5 6.53516 5.21062 4.44141 9.18187Z" fill="#FF3D00"/>
                            <path d="M15.5002 27.4995C18.7289 27.4995 21.6627 26.2638 23.8808 24.2545L20.0121 20.9807C18.7149 21.9672 17.1299 22.5007 15.5002 22.4995C12.2489 22.4995 9.48832 20.4263 8.44832 17.5332L4.37207 20.6738C6.44082 24.722 10.6421 27.4995 15.5002 27.4995Z" fill="#4CAF50"/>
                            <path d="M27.7569 12.5519H26.75V12.5H15.5V17.5H22.5644C22.0714 18.8853 21.1833 20.0957 20.01 20.9819L20.0119 20.9806L23.8806 24.2544C23.6069 24.5031 28 21.25 28 15C28 14.1619 27.9138 13.3438 27.7569 12.5519Z" fill="#1976D2"/>
                            </svg>Google</a>

                            <a class="mail" target="_blank" href="${outlook(event)}"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill-rule="evenodd" clip-rule="evenodd" image-rendering="optimizeQuality" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 17639 17639" id="outlook"><path fill="#1e70b6" d="M3502 12735V4798l6137-1111v10160l-6137-1112zm9657-952H9915l-2-2867 597 409c162 129 368 105 501 10l2399-1723 5 3859c-1 261 14 312-256 312zm-2623-3029-623-464V6844h2787c106-1 209 0 300 0 670-3 357 222 52 444l-2020 1470c-178 130-308 150-496-4zm-623-2651h3812c421 0 421 141 421 456v3763c0 199 0 261-211 261-212 0-212-54-211-246 0-12-1-27-1-36V6562H9913v-459zm-7832 9490h13493l1-13476-13494 1v13475z"></path><path fill="#1e70b6" d="M5669 9243c-357-1798 1435-2332 1698-831 331 1888-1421 2227-1698 831zm2230-424c0-965-422-1746-1355-1746-1391 0-1786 1874-1097 2950 277 432 1032 762 1694 395 473-262 758-852 758-1599z"></path></svg>Outlook</a>

                            <a class="mail" target="_blank" href="${office365(event)}"><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.199 24.2769C18.2336 24.0656 18.2424 8.78273 18.2095 8.44922C15.5343 9.06776 12.863 9.68576 10.1807 10.3059V21.0792C8.79979 21.6031 7.42379 22.1249 6.04835 22.646C6.0395 22.6412 6.03191 22.6386 6.02474 22.6337C6.0187 22.6294 6.01096 22.6229 6.0104 22.617C6.00604 22.5766 6.00056 22.5363 6.00056 22.4954C6 18.1424 6 13.7887 6 9.44643C6.06157 9.37277 6.14113 9.36354 6.21029 9.33889C10.1418 7.90972 14.0744 6.48207 18.0063 5.05303C18.1425 5.00346 18.2661 4.97923 18.417 5.02233C20.5744 5.63013 22.7339 6.2309 24.8929 6.83388C24.9242 6.84311 24.9556 6.85385 25 6.86776V25.2155C24.3801 25.3828 23.7657 25.5501 23.1507 25.7147C21.5852 26.1337 20.0188 26.5495 18.4544 26.9717C18.3012 27.0131 18.1672 27.01 18.0167 26.9561C14.1496 25.569 10.2807 24.1861 6.41298 22.8026C6.33666 22.7752 6.26187 22.7445 6.19764 22.6784C10.1983 23.2114 14.1989 23.7439 18.199 24.2769Z" fill="#DC3E15"/>
                            </svg> Office365</a>

                            <a class="mail" target="_blank" href="${yahoo(event)}"><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="16" fill="#5F01D1"/>
                            <path d="M12.625 25.5H8.75L10.3125 21.8125L6 11.5H9.9375L12.25 17.4375L14.5625 11.5H18.4375M22.0625 16.25H17.75L21.625 7H25.9375" fill="white"/>
                            <path d="M18.875 21.875C20.1867 21.875 21.25 20.8117 21.25 19.5C21.25 18.1883 20.1867 17.125 18.875 17.125C17.5633 17.125 16.5 18.1883 16.5 19.5C16.5 20.8117 17.5633 21.875 18.875 21.875Z" fill="white"/>
                            </svg> Yahoo</a>

                            <a class="mail" target="_blank" href="${ics(event)}" download><svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.2342 27.04C21.9275 28.3067 20.5009 28.1067 19.1275 27.5067C17.6742 26.8933 16.3409 26.8667 14.8075 27.5067C12.8875 28.3333 11.8742 28.0933 10.7275 27.04C4.22087 20.3333 5.18087 10.12 12.5675 9.74667C14.3675 9.84 15.6209 10.7333 16.6742 10.8133C18.2475 10.4933 19.7542 9.57333 21.4342 9.69333C23.4475 9.85333 24.9675 10.6533 25.9675 12.0933C21.8075 14.5867 22.7942 20.0667 26.6075 21.6C25.8475 23.6 24.8609 25.5867 23.2209 27.0533L23.2342 27.04ZM16.5409 9.66667C16.3409 6.69333 18.7542 4.24 21.5275 4C21.9142 7.44 18.4075 10 16.5409 9.66667Z" fill="black"/>
                            </svg> Apple</a>
                            </div>
                        </div>`;
                //////////

                // 1. Wstaw eventTypeDiv do odpowiedniego elementu zgodnie z godziną
                let specificHourContainer = document.querySelector(`.hour-${eventHour}.date-${eventDate}`);
                if (specificHourContainer) {
                    specificHourContainer.appendChild(eventTypeDiv);
                    specificHourContainer.classList.add('has-event');
                }

                // 2. Znajdź pierwszy element z has-event dla danego dnia
                let firstEventForTheDay = document.querySelector(`.date-${eventDate}.has-event`);

                // 3. Sprawdź liczbę wydarzeń danego dnia
                let allEventsForTheDay = document.querySelectorAll(`.date-${eventDate}.has-event .event`);

                if (firstEventForTheDay && allEventsForTheDay.length > 0) {
                    if (allEventsForTheDay.length > 3) {
                        // Wstaw pierwsze wydarzenie do .hour-07 danego dnia
                        let earliestHourContainer = document.querySelector(`.hour-07.date-${eventDate}`);
                        if (earliestHourContainer) {
                            earliestHourContainer.appendChild(allEventsForTheDay[0]);
                            earliestHourContainer.classList.add('has-event');
                        }
                    }

                    allEventsForTheDay.forEach((eventDiv, index) => {
                        // Jeżeli wydarzenie nie jest już w firstEventForTheDay (i nie jest pierwszym wydarzeniem w przypadku >3 wydarzeń), przeniesienie
                        if (index !== 0 || allEventsForTheDay.length <= 3) {
                            if (eventDiv.parentElement !== firstEventForTheDay) {
                                firstEventForTheDay.appendChild(eventDiv);
                            }
                        }
                    });
                }

                ///////////

                eventDotDiv.innerHTML = '<div class="category"></div>';

                if (eventDotContainer !== null) {
                    eventDotContainer.appendChild(eventDotDiv);
                    eventDotContainer.parentElement.classList.add('has-events');
                }

                eventMobileDiv.innerHTML = `
                <div class="category"></div>
                <button class="button-menu" title="menu"><svg class="icon-open" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line y1="1" x2="17" y2="1" stroke="black" stroke-width="2"/>
            <line y1="8" x2="17" y2="8" stroke="black" stroke-width="2"/>
            <line y1="15" x2="17" y2="15" stroke="black" stroke-width="2"/>
            </svg><svg class="icon-close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="close"><path d="M13.41,12l6.3-6.29a1,1,0,1,0-1.42-1.42L12,10.59,5.71,4.29A1,1,0,0,0,4.29,5.71L10.59,12l-6.3,6.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l6.29,6.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg></button>
                <h3 class="event-title">${eventType.title}</h3>
                <p class="event-time">${eventType.poczatek.toString().substring(0, 5)}-${eventType.koniec.toString().substring(0, 5)}</p>
                Prowadzący: <br /> ${eventProwadzacyName}
                <p class="event-place"><svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 3.21432C5.4606 3.21432 4.93331 3.37142 4.48481 3.66574C4.03631 3.96007 3.68675 4.37841 3.48033 4.86786C3.27391 5.35731 3.2199 5.89589 3.32513 6.41548C3.43036 6.93508 3.69011 7.41236 4.07153 7.78697C4.45294 8.16158 4.9389 8.41669 5.46794 8.52005C5.99697 8.6234 6.54534 8.57035 7.04368 8.36762C7.54203 8.16488 7.96797 7.82156 8.26764 7.38107C8.56732 6.94057 8.72727 6.42269 8.72727 5.89292C8.72727 5.18251 8.43994 4.5012 7.92847 3.99886C7.41701 3.49653 6.72332 3.21432 6 3.21432ZM6 7.50007C5.67636 7.50007 5.35998 7.40582 5.09089 7.22922C4.82179 7.05262 4.61205 6.80162 4.4882 6.50795C4.36434 6.21428 4.33194 5.89113 4.39508 5.57937C4.45822 5.26762 4.61407 4.98125 4.84292 4.75648C5.07177 4.53172 5.36334 4.37865 5.68076 4.31664C5.99818 4.25463 6.3272 4.28645 6.62621 4.40809C6.92522 4.52974 7.18078 4.73573 7.36059 5.00003C7.54039 5.26432 7.63636 5.57505 7.63636 5.89292C7.63636 6.31916 7.46396 6.72795 7.15708 7.02935C6.85021 7.33075 6.43399 7.50007 6 7.50007ZM6 0C4.40926 0.00177238 2.88418 0.623201 1.75935 1.72795C0.634525 2.8327 0.00180459 4.33056 0 5.89292C0 7.99562 0.989318 10.2242 2.86364 12.3383C3.70583 13.2936 4.65372 14.1538 5.68977 14.9031C5.78149 14.9662 5.89075 15 6.00273 15C6.1147 15 6.22397 14.9662 6.31568 14.9031C7.34983 14.1535 8.29587 13.2933 9.13636 12.3383C11.008 10.2242 12 7.99562 12 5.89292C11.9982 4.33056 11.3655 2.8327 10.2406 1.72795C9.11582 0.623201 7.59075 0.00177238 6 0ZM6 13.7948C4.87295 12.9242 1.09091 9.72666 1.09091 5.89292C1.09091 4.61418 1.60812 3.38782 2.52875 2.48362C3.44938 1.57941 4.69803 1.07144 6 1.07144C7.30197 1.07144 8.55062 1.57941 9.47125 2.48362C10.3919 3.38782 10.9091 4.61418 10.9091 5.89292C10.9091 9.72532 7.12705 12.9242 6 13.7948Z" fill="black"/>
                    </svg>${eventType.lokalizacja}</p>
                    <div class="informations">
                        <div class="default-buttons">
                        <a href="/?p=${eventSzkolenie}"><svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="34" height="34" rx="17" fill="#E34212"/>
                        <path d="M20 25.5C20 25.8978 19.842 26.2794 19.5607 26.5607C19.2794 26.842 18.8978 27 18.5 27C17.7044 27 16.9413 26.6839 16.3787 26.1213C15.8161 25.5587 15.5 24.7956 15.5 24V16.5C15.1022 16.5 14.7206 16.342 14.4393 16.0607C14.158 15.7794 14 15.3978 14 15C14 14.6022 14.158 14.2206 14.4393 13.9393C14.7206 13.658 15.1022 13.5 15.5 13.5C16.2956 13.5 17.0587 13.8161 17.6213 14.3787C18.1839 14.9413 18.5 15.7044 18.5 16.5V24C18.8978 24 19.2794 24.158 19.5607 24.4393C19.842 24.7206 20 25.1022 20 25.5ZM14 8.25C14 7.80499 14.132 7.36998 14.3792 6.99997C14.6264 6.62996 14.9778 6.34157 15.389 6.17127C15.8001 6.00097 16.2525 5.95642 16.689 6.04323C17.1254 6.13005 17.5263 6.34434 17.841 6.65901C18.1557 6.97368 18.37 7.37459 18.4568 7.81105C18.5436 8.2475 18.499 8.6999 18.3287 9.11104C18.1584 9.52217 17.87 9.87357 17.5 10.1208C17.13 10.368 16.695 10.5 16.25 10.5C15.6533 10.5 15.081 10.2629 14.659 9.84099C14.2371 9.41903 14 8.84674 14 8.25Z" fill="white"/>
                        </svg>info</a>
                        
                        <a href="${eventType.prowadzacy.link}"><svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="34" height="34" rx="17" fill="#E34212"/>
                        <path d="M26.6489 24.8756C25.2211 22.4072 23.0208 20.6372 20.4529 19.7981C21.7231 19.042 22.7099 17.8898 23.2619 16.5185C23.8139 15.1473 23.9004 13.6327 23.5083 12.2075C23.1162 10.7823 22.2671 9.52515 21.0914 8.62921C19.9156 7.73327 18.4783 7.24805 17.0001 7.24805C15.5219 7.24805 14.0846 7.73327 12.9089 8.62921C11.7332 9.52515 10.8841 10.7823 10.492 12.2075C10.0998 13.6327 10.1864 15.1473 10.7384 16.5185C11.2903 17.8898 12.2772 19.042 13.5473 19.7981C10.9795 20.6362 8.77919 22.4062 7.35138 24.8756C7.29902 24.961 7.26429 25.056 7.24924 25.155C7.23419 25.254 7.23912 25.355 7.26375 25.4521C7.28837 25.5492 7.33219 25.6404 7.39262 25.7202C7.45305 25.8001 7.52887 25.867 7.61559 25.9171C7.70232 25.9672 7.7982 25.9995 7.89758 26.0119C7.99695 26.0243 8.09782 26.0167 8.19421 25.9896C8.29061 25.9624 8.38059 25.9162 8.45884 25.8537C8.53709 25.7912 8.60203 25.7136 8.64982 25.6256C10.4161 22.5731 13.5379 20.7506 17.0001 20.7506C20.4623 20.7506 23.5842 22.5731 25.3504 25.6256C25.3982 25.7136 25.4632 25.7912 25.5414 25.8537C25.6197 25.9162 25.7097 25.9624 25.806 25.9896C25.9024 26.0167 26.0033 26.0243 26.1027 26.0119C26.2021 25.9995 26.2979 25.9672 26.3847 25.9171C26.4714 25.867 26.5472 25.8001 26.6076 25.7202C26.6681 25.6404 26.7119 25.5492 26.7365 25.4521C26.7611 25.355 26.7661 25.254 26.751 25.155C26.736 25.056 26.7012 24.961 26.6489 24.8756ZM11.7501 14.0006C11.7501 12.9622 12.058 11.9472 12.6349 11.0839C13.2118 10.2205 14.0317 9.54759 14.991 9.15023C15.9504 8.75287 17.006 8.6489 18.0244 8.85147C19.0428 9.05405 19.9782 9.55406 20.7124 10.2883C21.4467 11.0225 21.9467 11.958 22.1493 12.9764C22.3518 13.9948 22.2479 15.0504 21.8505 16.0097C21.4531 16.969 20.7802 17.7889 19.9169 18.3658C19.0535 18.9427 18.0385 19.2506 17.0001 19.2506C15.6082 19.2491 14.2737 18.6955 13.2895 17.7113C12.3052 16.727 11.7516 15.3925 11.7501 14.0006Z" fill="white"/>
                        </svg>prowadzący</a>
                        
                        <button class="addToCalendar" data-state="false"><svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="34" height="34" rx="17" fill="#E34212"/>
                        <path d="M24.5 8H22.25V7.25C22.25 7.05109 22.171 6.86032 22.0303 6.71967C21.8897 6.57902 21.6989 6.5 21.5 6.5C21.3011 6.5 21.1103 6.57902 20.9697 6.71967C20.829 6.86032 20.75 7.05109 20.75 7.25V8H13.25V7.25C13.25 7.05109 13.171 6.86032 13.0303 6.71967C12.8897 6.57902 12.6989 6.5 12.5 6.5C12.3011 6.5 12.1103 6.57902 11.9697 6.71967C11.829 6.86032 11.75 7.05109 11.75 7.25V8H9.5C9.10218 8 8.72064 8.15804 8.43934 8.43934C8.15804 8.72064 8 9.10218 8 9.5V24.5C8 24.8978 8.15804 25.2794 8.43934 25.5607C8.72064 25.842 9.10218 26 9.5 26H24.5C24.8978 26 25.2794 25.842 25.5607 25.5607C25.842 25.2794 26 24.8978 26 24.5V9.5C26 9.10218 25.842 8.72064 25.5607 8.43934C25.2794 8.15804 24.8978 8 24.5 8ZM11.75 9.5V10.25C11.75 10.4489 11.829 10.6397 11.9697 10.7803C12.1103 10.921 12.3011 11 12.5 11C12.6989 11 12.8897 10.921 13.0303 10.7803C13.171 10.6397 13.25 10.4489 13.25 10.25V9.5H20.75V10.25C20.75 10.4489 20.829 10.6397 20.9697 10.7803C21.1103 10.921 21.3011 11 21.5 11C21.6989 11 21.8897 10.921 22.0303 10.7803C22.171 10.6397 22.25 10.4489 22.25 10.25V9.5H24.5V12.5H9.5V9.5H11.75ZM24.5 24.5H9.5V14H24.5V24.5ZM20.9056 16.4694C20.9754 16.539 21.0307 16.6217 21.0684 16.7128C21.1062 16.8038 21.1256 16.9014 21.1256 17C21.1256 17.0986 21.1062 17.1962 21.0684 17.2872C21.0307 17.3783 20.9754 17.461 20.9056 17.5306L16.4056 22.0306C16.336 22.1004 16.2533 22.1557 16.1622 22.1934C16.0712 22.2312 15.9736 22.2506 15.875 22.2506C15.7764 22.2506 15.6788 22.2312 15.5878 22.1934C15.4967 22.1557 15.414 22.1004 15.3444 22.0306L13.0944 19.7806C12.9536 19.6399 12.8746 19.449 12.8746 19.25C12.8746 19.051 12.9536 18.8601 13.0944 18.7194C13.2351 18.5786 13.426 18.4996 13.625 18.4996C13.824 18.4996 14.0149 18.5786 14.1556 18.7194L15.875 20.4397L19.8444 16.4694C19.914 16.3996 19.9967 16.3443 20.0878 16.3066C20.1788 16.2688 20.2764 16.2494 20.375 16.2494C20.4736 16.2494 20.5712 16.2688 20.6622 16.3066C20.7533 16.3443 20.836 16.3996 20.9056 16.4694Z" fill="white"/>
                        </svg> Dodaj do kalendarza</button>
                        <a href="${zapisy}"><svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="34" height="34" rx="17" fill="#E34212"/>
                        <path d="M25.5607 18.0607C25.842 17.7794 26 17.3978 26 17C26 16.6022 25.842 16.2206 25.5607 15.9393C25.2794 15.658 24.8978 15.5 24.5 15.5H18.5V9.5C18.5 9.10218 18.342 8.72064 18.0607 8.43934C17.7794 8.15804 17.3978 8 17 8C16.6022 8 16.2206 8.15804 15.9393 8.43934C15.658 8.72064 15.5 9.10218 15.5 9.5V15.5H9.5C9.10218 15.5 8.72064 15.658 8.43934 15.9393C8.15804 16.2206 8 16.6022 8 17C8 17.3978 8.15804 17.7794 8.43934 18.0607C8.72064 18.342 9.10218 18.5 9.5 18.5H15.5V24.5C15.5 24.8978 15.658 25.2794 15.9393 25.5607C16.2206 25.842 16.6022 26 17 26C17.3978 26 17.7794 25.842 18.0607 25.5607C18.342 25.2794 18.5 24.8978 18.5 24.5V18.5H24.5C24.8978 18.5 25.2794 18.342 25.5607 18.0607Z" fill="white"/>
                        </svg>zapisz się</a>
                        </div>

                        <div class="add-to-calendar">
                        <a class="mail" target="_blank" href="${google(event)}"><svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M27.7569 12.5519H26.75V12.5H15.5V17.5H22.5644C21.5338 20.4106 18.7644 22.5 15.5 22.5C11.3581 22.5 8 19.1419 8 15C8 10.8581 11.3581 7.5 15.5 7.5C17.4119 7.5 19.1513 8.22125 20.4756 9.39937L24.0113 5.86375C21.7788 3.78312 18.7925 2.5 15.5 2.5C8.59687 2.5 3 8.09687 3 15C3 21.9031 8.59687 27.5 15.5 27.5C22.4031 27.5 28 21.9031 28 15C28 14.1619 27.9138 13.3438 27.7569 12.5519Z" fill="#FFC107"/>
                        <path d="M4.44141 9.18187L8.54828 12.1938C9.65953 9.4425 12.3508 7.5 15.5002 7.5C17.412 7.5 19.1514 8.22125 20.4758 9.39937L24.0114 5.86375C21.7789 3.78312 18.7927 2.5 15.5002 2.5C10.6989 2.5 6.53516 5.21062 4.44141 9.18187Z" fill="#FF3D00"/>
                        <path d="M15.5002 27.4995C18.7289 27.4995 21.6627 26.2638 23.8808 24.2545L20.0121 20.9807C18.7149 21.9672 17.1299 22.5007 15.5002 22.4995C12.2489 22.4995 9.48832 20.4263 8.44832 17.5332L4.37207 20.6738C6.44082 24.722 10.6421 27.4995 15.5002 27.4995Z" fill="#4CAF50"/>
                        <path d="M27.7569 12.5519H26.75V12.5H15.5V17.5H22.5644C22.0714 18.8853 21.1833 20.0957 20.01 20.9819L20.0119 20.9806L23.8806 24.2544C23.6069 24.5031 28 21.25 28 15C28 14.1619 27.9138 13.3438 27.7569 12.5519Z" fill="#1976D2"/>
                        </svg>Google</a>

                        <a class="mail" target="_blank" href="${outlook(event)}"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill-rule="evenodd" clip-rule="evenodd" image-rendering="optimizeQuality" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 17639 17639" id="outlook"><path fill="#1e70b6" d="M3502 12735V4798l6137-1111v10160l-6137-1112zm9657-952H9915l-2-2867 597 409c162 129 368 105 501 10l2399-1723 5 3859c-1 261 14 312-256 312zm-2623-3029-623-464V6844h2787c106-1 209 0 300 0 670-3 357 222 52 444l-2020 1470c-178 130-308 150-496-4zm-623-2651h3812c421 0 421 141 421 456v3763c0 199 0 261-211 261-212 0-212-54-211-246 0-12-1-27-1-36V6562H9913v-459zm-7832 9490h13493l1-13476-13494 1v13475z"></path><path fill="#1e70b6" d="M5669 9243c-357-1798 1435-2332 1698-831 331 1888-1421 2227-1698 831zm2230-424c0-965-422-1746-1355-1746-1391 0-1786 1874-1097 2950 277 432 1032 762 1694 395 473-262 758-852 758-1599z"></path></svg>Outlook</a>

                        <a class="mail" target="_blank" href="${office365(event)}"><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.199 24.2769C18.2336 24.0656 18.2424 8.78273 18.2095 8.44922C15.5343 9.06776 12.863 9.68576 10.1807 10.3059V21.0792C8.79979 21.6031 7.42379 22.1249 6.04835 22.646C6.0395 22.6412 6.03191 22.6386 6.02474 22.6337C6.0187 22.6294 6.01096 22.6229 6.0104 22.617C6.00604 22.5766 6.00056 22.5363 6.00056 22.4954C6 18.1424 6 13.7887 6 9.44643C6.06157 9.37277 6.14113 9.36354 6.21029 9.33889C10.1418 7.90972 14.0744 6.48207 18.0063 5.05303C18.1425 5.00346 18.2661 4.97923 18.417 5.02233C20.5744 5.63013 22.7339 6.2309 24.8929 6.83388C24.9242 6.84311 24.9556 6.85385 25 6.86776V25.2155C24.3801 25.3828 23.7657 25.5501 23.1507 25.7147C21.5852 26.1337 20.0188 26.5495 18.4544 26.9717C18.3012 27.0131 18.1672 27.01 18.0167 26.9561C14.1496 25.569 10.2807 24.1861 6.41298 22.8026C6.33666 22.7752 6.26187 22.7445 6.19764 22.6784C10.1983 23.2114 14.1989 23.7439 18.199 24.2769Z" fill="#DC3E15"/>
                        </svg> Office365</a>

                        <a class="mail" target="_blank" href="${yahoo(event)}"><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="16" cy="16" r="16" fill="#5F01D1"/>
                        <path d="M12.625 25.5H8.75L10.3125 21.8125L6 11.5H9.9375L12.25 17.4375L14.5625 11.5H18.4375M22.0625 16.25H17.75L21.625 7H25.9375" fill="white"/>
                        <path d="M18.875 21.875C20.1867 21.875 21.25 20.8117 21.25 19.5C21.25 18.1883 20.1867 17.125 18.875 17.125C17.5633 17.125 16.5 18.1883 16.5 19.5C16.5 20.8117 17.5633 21.875 18.875 21.875Z" fill="white"/>
                        </svg> Yahoo</a>

                        <a class="mail" target="_blank" href="${ics(event)}" download><svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.2342 27.04C21.9275 28.3067 20.5009 28.1067 19.1275 27.5067C17.6742 26.8933 16.3409 26.8667 14.8075 27.5067C12.8875 28.3333 11.8742 28.0933 10.7275 27.04C4.22087 20.3333 5.18087 10.12 12.5675 9.74667C14.3675 9.84 15.6209 10.7333 16.6742 10.8133C18.2475 10.4933 19.7542 9.57333 21.4342 9.69333C23.4475 9.85333 24.9675 10.6533 25.9675 12.0933C21.8075 14.5867 22.7942 20.0667 26.6075 21.6C25.8475 23.6 24.8609 25.5867 23.2209 27.0533L23.2342 27.04ZM16.5409 9.66667C16.3409 6.69333 18.7542 4.24 21.5275 4C21.9142 7.44 18.4075 10 16.5409 9.66667Z" fill="black"/>
                        </svg> Apple</a>
                        </div>
                    </div>`;

                if (eventMobileContainer !== null) {
                    eventMobileContainer.appendChild(eventMobileDiv);
                    // if (pickedDate != false) {
                    //     if (eventDate == pickedDate)
                    //         eventMobileContainer.appendChild(eventMobileDiv);

                    // } else
                    //     eventMobileContainer.appendChild(eventMobileDiv);
                }
            }
        }

        refreshTile();
        isDataFetching = false;
        inprogress = false;
    }
}
